import React from 'react';
import styled from 'styled-components';
import {push} from 'connected-react-router';
// import {Search} from '../UIkit'
import {useDispatch} from 'react-redux';

const NavBar = styled.header`
width: 100%;
background-color: #fff;
display:flex;
justify-content:center;
align-items: center;
img{
    margin-right:2.5em;
}
`;

const Size = styled.div`
padding: 1em;
width: 1000px;
display:flex;
justify-content:center;
 img {
     cursor: pointer;
 }
`;


const Button = styled.button`
   background: #f5f5f5;
    border-radius: 4px;
    width: 6em;
    padding: 1em 0.5em;
    margin-left: 1.5em;
`;

const SearchContents = styled.div`
width:100%;
   display: flex;
   padding: 0.7em 0;
   align-items:center;
   input {
    background: #f5f5f5;
border-radius: 4px;

width: 100%;
height: 40px;
line-height: 1.5;
    font-size: 16px;
    border: 1px solid #ccc;
   }
   
`;

const Nav = () => {
    const dispatch = useDispatch();
 return(
     <NavBar>
        <Size>
    <img onClick={() => dispatch(push('/'))} src="https://placehold.jp/200x60.png" alt="siteTitle"/>
    {/* <Search /> */}
    </Size>
     </NavBar>
 )
}

export default Nav;