import React, {useCallback, useEffect, useState} from 'react';
import {TextInput} from "../UIkit";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    checkIcon: {
        float: 'right'
    },
    iconCell: {
        padding: 0,
        height: 48,
        width: 48
    }
})

const SetSizesArea = (props) => {
    const classes = useStyles()

    const [index, setIndex] = useState(0),
          [size, setSize] = useState(""),
          [quantity, setQuantity] = useState(0);

// input関数に渡すset関数瀬亭 useCallback
// textInput の onchange は引数としてeventを受け取らないといけない
    const inputSize = useCallback((event) => {
        // 実際に入力された値↓
        setSize(event.target.value)
    }, [setSize]);

    const inputQuantity = useCallback((event) => {
        setQuantity(event.target.value)
    }, [setQuantity]);

    // const addSize = (index, size, quantity) => {
    //     if (size === "" || quantity === 0) {
    //         // Required input is blank
    //         return false
    //     } else {
    //         if (index === props.sizes.length) {
    //             props.setSizes(prevState => [...prevState, {size: size, quantity: quantity}]);
    //         setIndex(index + 1);
    //         setSize("");
    //         setQuantity(0)
    //         } else {
    //             const newSizes = props.sizes;
    //             newSizes[index] = {size: size, quantity: quantity};
    //             props.setSizes(newSizes);
    //             setIndex(newSizes.length);
    //             setSize("");
    //             setQuantity(0);
    //         }
    //     }
    // };

    // サイズを追加する値
    const addSize = (index, size, quantity) => {
        // 空白へのバリデーション
        if (size === "" || quantity === 0) {
            // Required input is blank
            return false
        } else {
            // サイズが新規追加される時の処理
            // sizes の length の値が一致している時
            if (index === props.sizes.length) {
        // sizesの更新 配列に要素を取得する
            props.setSizes(prevState => [...prevState, {size: size, quantity: quantity}]);
            // ローカルstateを初期値に戻す 次に進ませる
            setIndex(index + 1);
            setSize("");
            setQuantity(0)
            } else {
                // editSizeで変更された時 の処理 (値が一致してない時)
                const newSizes = props.sizes;
                newSizes[index] = {size: size, quantity: quantity};
                // 丸っと変更する
                props.setSizes(newSizes);
                setIndex(newSizes.length);
                // 初期値に戻す
                setSize("");
                setQuantity(0);
            }
        }
    }

    // 編集できるようにする関数 Edit Icon で呼び出す関数
    const editSize = (index, size, quantity) => {
        // 引数で渡ってきた値を 現在のstateにセットする　
        setIndex(index)
        setSize(size)
        setQuantity(quantity)
    }

    const deleteSize = (deleteIndex) => {
        const newSizes = props.sizes.filter((item, i) => i !== deleteIndex)
        props.setSizes(newSizes);
    }

    // 編集画面時になった時 初期値は0だが 編集画面では複数に対応しないといけない
    // useMemoでも良い。props.size.length が変わった時に index を更新する
    useEffect(() => {
        setIndex(props.sizes.length)
    },[props.sizes.length])
    // const memoIndex = useMemo(() => {
    //     setIndex(props.sizes.length)
    // },[props.sizes.length]);

    return (
        <div aria-label="サイズ展開">
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>サイズ</TableCell>
                            <TableCell>数量</TableCell>
                            <TableCell className={classes.iconCell} />
                            <TableCell className={classes.iconCell} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* propsでくるsize配列のながさが0以上の時 */}
                        {props.sizes.length > 0 && (
                            // index は何番目の要素か
                            props.sizes.map((item, i) => (
                                <TableRow key={item.size}>
                                    <TableCell component="th" scope="row">{item.size}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>
                                    {/* <IconButton className={classes.iconCell}> */}
                                    {/* 今、何番目のサイズを修正しているのかを i(index) がお知らせすることができる */}
                                    <IconButton className={classes.iconCell} onClick={()=> editSize(i,item.size, item.quantity)}>
                                        {/* <IconButton className={classes.iconCell} onClick={()=> editSize(index,item.size,item.quantity)}> */}
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    {/* <TableCell className={classes.iconCell}> */}
                                    <TableCell className={classes.iconCell} onClick={() => deleteSize(i)}>
                                        <IconButton className={classes.iconCell}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
                <div>
                    <TextInput
                        fullWidth={false} label={"サイズ"} multiline={false}
                        onChange={inputSize} rows={1} value={size} type={"text"}
                    />
                    <TextInput
                        fullWidth={false} label={"数量"} multiline={false}
                        onChange={inputQuantity} rows={1} value={quantity} type={"number"}
                    />
                </div>
                {/* <IconButton className={classes.checkIcon}> */}
                <IconButton className={classes.checkIcon} onClick={() => addSize(index,size, quantity)}>
                    <CheckCircleIcon/>
                </IconButton>
            </TableContainer>
            <div className="module-spacer--small"/>
        </div>
    );
};

export default SetSizesArea;
