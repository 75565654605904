import React,{useState} from 'react';
import {push} from "connected-react-router"
import styled from 'styled-components';
import NoImage from '../../assets/img/no_image.png';
import {useDispatch,useSelector} from "react-redux";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import {ProductMenu} from '../Products';
// import {getProducts} from '../../reducks/products/selectors';
// import { getUserId } from '../../reducks/users/selectors';
import {getUserRole} from "../../reducks/users/selectors";

const Icon = styled.div`
    /* padding: 1em; */
    position: absolute;
    bottom: 1em;
    left: 1em;
    background-color: #ea352d;
    transition: all ease-out .3s;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    :hover {
        background-color: #aa2e28;
        transition: all ease-out .3s;
    }
`;


const Card = styled.div`
position: relative;
    margin: 0 0.8em 1em 0;
    background-color:#fff;
    width: 100%;
    max-width: 16em;
    border-radius:1em;
    border: solid 2px #eaeaea;
    position: relative;
    transition: all ease-out .3s;
    cursor: pointer;
    img {
        border-radius: 0.8em 0.8em 0 0;
        /* width: 100%; */
        width: 255.7px;
       height: 255.7px;
       object-fit: cover;
    }
    :hover{
        transition: all ease-out .3s;
        background-color: #f5f5f5;;
    }
`;

const TextArea = styled.div`
    padding: 1em;
`;

const Price = styled.div`
    align-items: center;
    background: rgba(0,0,0,.4);
    border-radius: 0 14px 14px 0;
position: absolute;
top: 190px;
left: 0;
    p {
        color: #fff;
        font-size: 1em;
        padding: 0.3em 1em;
    }
`;


const ProductCard = (props) => {

        const selector = useSelector(state => state);
    const userRole = getUserRole(selector)
    const isAdministrator = (userRole === "administrator");

    const [dropdown, setDropdown] = useState(false);
    const dispatch = useDispatch()

    const images = (props.images.length > 0) ? props.images : [{path:NoImage}]
    const price = props.price.toLocaleString();
  
        // DropDown Functions
        const onMouseEnter = () => {
            if (window.innerWidth < 960) {
                setDropdown(false);
            }else{
                setDropdown(true);
            }
        }
        const onMouseLeave = () => {
            if (window.innerWidth < 960) {
                setDropdown(false);
            }else{
                setDropdown(false);
            }
        }


 return(
     <Card>
         {isAdministrator && (
         <Icon
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
         >
           <BiIcons.BiDotsHorizontalRounded className="product__Icon" />
           {dropdown && <ProductMenu id={props.id}/>}
      </Icon>
        )}
         <img
            src={images[0].path}
            onClick={() => dispatch(push('/product/'+props.id))}
            />
          <TextArea>
             <p>{props.name}</p>
         </TextArea>
         <Price>
         <p>￥{price}</p>
         </Price>
     </Card>
 )
}

export default ProductCard;




// import React,{useState} from 'react';
// import {push} from "connected-react-router"
// import styled from 'styled-components';
// import NoImage from '../../assets/img/no_image.png';
// import {useDispatch, useSelector} from "react-redux";
// import * as AiIcons from "react-icons/ai";
// import * as BiIcons from "react-icons/bi";
// import {ProductMenu} from '../Products';
// // import {ProductMenu} from '../Products';
// import {getUserRole} from "../../reducks/users/selectors";


// const Card = styled.div`
//     margin: 0 0.8em 1em 0;
//     background-color:#fff;
//     width: 100%;
//     max-width: 16em;
//     border-radius:1em;
//     border: solid 2px #eaeaea;
//     position: relative;
//     transition: all ease-out .3s;
//     cursor: pointer;
//     img {
//         border-radius: 0.8em 0.8em 0 0;
//         /* width: 100%; */
//         width: 255.7px;
//     　　height: 255.7px;
//     　　object-fit: cover;
//     }
//     :hover{
//         transition: all ease-out .3s;
//         background-color: #f5f5f5;;
//     }
// `;

// const TextArea = styled.div`
//     padding: 1em;
// `;

// const Price = styled.div`
//     align-items: center;
//     background: rgba(0,0,0,.4);
//     border-radius: 0 14px 14px 0;
// position: absolute;
// top: 190px;
// left: 0;
//     p {
//         color: #fff;
//         font-size: 1em;
//         padding: 0.3em 1em;
//     }
// `;

// const Icon = styled.div`
//     /* padding: 1em; */
//     position: absolute;
//     bottom: 1em;
//     left: 1em;
//     background-color: #ea352d;
//     transition: all ease-out .3s;
//     width: 47px;
//     height: 47px;
//     border-radius: 50%;
//     :hover {
//         background-color: #aa2e28;
//         transition: all ease-out .3s;
//     }
// `;


// const ProductCard = (props) => {
//     const selector = useSelector(state => state);
//     const userRole = getUserRole(selector)
//     const isAdministrator = (userRole === "administrator");

//     const [click,setClick] = useState(false);
//     const handleClick = () => setClick(!click)
//     // const [sidebar, setSidebar] = useState(null)
//     const dispatch = useDispatch()

//      // DropDown Functions
//     //  const showSidebar = () => setSidebar(!sidebar)

//     const images = (props.images.length > 0) ? props.images : [{path:NoImage}]
//     const price = props.price.toLocaleString();
  


//  return(
     
// <>
// <Card>
// <Icon>
//            <BiIcons.BiDotsHorizontalRounded className="product__Icon" />
// </Icon>
//          <img
//             src={images[0].path}
//             onClick={() => dispatch(push('/product/'+props.id))}
//             />
//           <TextArea>
//              <p>{props.name}</p>
//          </TextArea>
//          <Price>
//          <p>￥{price}</p>
//          </Price>
         
//      </Card>

//      {/* {isAdministrator && ( */}
//      <nav onClick={handleClick} className={click ? 'dropdown__menu' : 'dropdown__menu'}>
// <ul className='nav-menu2-items'>
//     <li className='navbar-toggle'>
//         <a className='menu-bars2'>
//             <AiIcons.AiOutlineClose />
//         </a>
//     </li>

//     <ProductMenu />

    
// </ul>
// </nav>
//  {/* )} */}



// </>
// )
// }

// export default ProductCard;

{/* <nav className={sidebar ? 'nav-menu2 active' : 'nav-menu2'}>
<ul className='nav-menu2-items' onClick={showSidebar}>
    <li className='navbar-toggle'>
        <a className='menu-bars2'>
            <AiIcons.AiOutlineClose />
        </a>
    </li>

    <ProductMenu />

    
</ul>
</nav> */}