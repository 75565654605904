import React, { useCallback } from 'react';
import {storage} from '../../firebase';
import ImagePreview from '../Products/ImagePreview';
import * as RiIcons from "react-icons/ri";
import styled from 'styled-components';

const Images = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    img {
        margin: 0 0.6em 1em 0.6em;
    }
`;

const TextArea = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    transition: all ease-out .3s;
    :hover {
        color: #ea352d;
        transition: all ease-out .3s;
    }
    p {
        padding-right: 0.8em;
    }
`;

const ImageArea = (props) => {

    // -----------------
    // deleteImage
    // -----------------
    // 引数 として 画像id をもつ
    const deleteImage = useCallback(async (id) => {
        const ret = window.confirm('この画像を削除しますか？');
        // false にしたら処理は行わない
        if (!ret) {
            return false
        // それ以外ならば、.filter() を使用して、選択した 画像id 以外を抽出する newImage定数 を作成
        } else {
            const newImages = props.images.filter(image => image.id !== id)
            // props.setImage に newImage は再配列にする
            props.setImages(newImages);
            // storageCloudのimagesから画像idを削除する
            return storage.ref('images').child(id).delete()
        }
    }, [props.images])

    // -----------------
    // uploadImage
    // -----------------
    // 引数に event を渡す。 event は OnChangeにより怒るイベントのこと
    const uploadImage = useCallback((event) => {
        // アップロードしたファイルを定数化している
        const file = event.target.files;
        // file type のインスタンス生成 を 変数化 する
        let blob = new Blob(file, { type: "image/jpeg" });
        // ランダム生成に使うアルファベット・数字 を生成
        const S="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        // 16文字数に設定 
        const N = 16;
        // Array.from() で Array をインスタンス化、 crypto.getRandomValues により 暗号強度の強い乱数値を取得。new Uint32Array で新しい Uint32Array オブジェクトを生成。
        // map() にて 関数を配列のすべての要素に対して呼す。 .join で 配列の前要素を連結させる。
        const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N))).map((n)=>S[n%S.length]).join('')
    
        // images のパス にファイル上に名前をアップロード
        const uploadRef = storage.ref('images').child(fileName);
        // パスに名前を書き換えてファイルを firebase にアップロードする
        const uploadTask = uploadRef.put(blob);
                              
        // アップロードが実行されたら ダウンロードURL を取得する。
        // ダウンロード取得をしたら、更新前の画像URLと新しくアップロードした画像URLに更新する
        uploadTask.then(() => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // {id: fileName, path: downloadURL} の オブジェクト が jsx の images
                // id が 文字・数字をランダム生成したもの / path が downloadURL
                const newImage = {id: fileName, path: downloadURL};
                props.setImages((prevState => [...prevState, newImage]))
            });
        })
    // 引数として 更新された画像を更新する。 (まるまる更新した時だけ更新させる)
    }, [props.setImages])


 return(
  <div>

      <Images>
         {/* props で渡ってきた images が 1枚以上あれば <ImagePreview /> を .map として回す  (props で pathをもつ)*/}
         {props.images.length > 0 && (
                 props.images.map(image => <ImagePreview delete={deleteImage} id={image.id} path={image.path} key={image.id} />)
             )}
      </Images>

      <TextArea>

         <p> <span>商品画像を登録する</span></p>
          <label>
          <RiIcons.RiImageAddFill className="add__Image" />
          <input className="icon-hide" type="file"
                 onChange={(event) => uploadImage(event)}
                  />
          </label>

      </TextArea>
  </div>
 )
}

export default ImageArea;