import React from 'react';
import {DropDownNavbar} from '../components/common';
import {ImageUpload} from '../components/UIkit';
import {ProductOther} from '../components/UIkit';
import {Carousel} from '../components/Home';
import {TimeText} from '../components/User';

const test = () => {
   
 return(
  <div>
    {/* <DropDownNavbar /> */}
    {/* <ImageUpload /> */}
    {/* <ProductOther /> */}
    {/* <Carousel /> */}
    {/* <TimeText> */}
  </div> 
 )
}

export default test;
