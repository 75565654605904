import React from 'react';
import styled from 'styled-components';
import {useDispatch} from "react-redux";
import {push} from 'connected-react-router';

const Chip = styled.p`
    background-color: rgb(239, 239, 239);
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    margin: 0 0.5em;
`;


const CategoryChip = (props) => {
  const dispatch = useDispatch();

 return(
  <a href={props.path}>
<Chip onClick={() => dispatch(push(props.path))}>
    {props.name}
</Chip> 
  </a>
 )
}

export default CategoryChip;