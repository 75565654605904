const initialState = {
    contacts: {
       list: []
    },
    categories: {
        id: ""
     },
    products: {
        list: []
     },
    users: {
       like: [],
       cart: [],
       isSignedIn: false,
       uid: "",
       username: "",
       email: "",
       prefecture: "",
       city: "",
       other: "",
       post: "",
       description: "",
       images: ""
    }
};

export default initialState