// import React, { useEffect,useState,useCallback } from 'react';
// import { db, FirebaseTimestamp } from '../firebase/index';
// import { useSelector,useDispatch } from "react-redux";
// // import {ImageArea} from '../components/UIkit';
// import styled from 'styled-components';
// import {ProductButton,ImageSwiper} from '../components/Products';
// import * as AiIcons from "react-icons/ai";
// import * as FaIcons from "react-icons/fa";
// import * as MdIcons from "react-icons/md";
// import * as RiIcons from "react-icons/ri";
// import {IconContext} from 'react-icons';
// import {addProductToCart, addProductToLike} from '../reducks/users/operations';
// import {getUserId} from '../reducks/users/selectors';

// const CenterSpacer = styled.div`
//  margin: 0 1em;
// `;


// const Table = styled.div`
//  display:flex;
//  align-items: center;
//  text-align:center;
//  justify-content: center;
// `;

// const Text = styled.p`
//  padding-left:0.8em;
// `;

// const Head = styled.div`
//  display:flex;
//  align-items: center;
//   p{
//       padding-left:0.5em;
//   }
// `;

// const Contents = styled.div`
//    width: 50%;
//    h2 {
//        padding-top:0;
//    }
// `;

// const ContentsPage = styled.div`
//    display:flex;
//    justify-content:center;
//    padding-top:5em;
// `;

// const Price = styled.p`
//   font-size: 2.5em;
//   font-weight:bold;
// `;




// const User = () => {
//       // useState setProduct を使用して product を 更新させる
//       const [users,setUsers] = useState(null);
//     // 現在のStateを取得する
//     const selector = useSelector((state) => state);
//     const dispatch = useDispatch();
//     // const uid = getUserId(selector);
//     // 現在の Syore の URL の pathname を取得する
//     const path = selector.router.location.pathname;
//     // 定数id には、2番目のpathname(id)を取得する
//     console.log(path)
//     const uid = path.split('/user/')[1];
//     console.log(uid)

//     // const ButtonLists = [
//     //     {
//     //       label: '購入手続き',
//     //       color: 'button__red',
//     //       path: '/signup',
//     //       icon: <FaIcons.FaCartPlus />
//     //     },
//     //     {
//     //       label: 'お気に入り',
//     //       path: '/signin',
//     //       color: 'button__pink',
//     //       icon: <AiIcons.AiOutlineHeart />
//     //       }
//     //   ];


//     // -------------------------------------
//     //  Firebase から ファイルを取得する
//     // (return をレンダーしてから useEffect を読み込む)
//     // -------------------------------------
//     useEffect(() => {
//         // collection の products から id を取得する
//         // id を取得するには、上記の selecor と path が必要
//         db.collection('users').doc(uid).get()
//         // ドキュメント から 取り出したいデータ を取り出す
//         .then(doc => {
//             const data = doc.data();
//             // useState の setProduct にdata を渡して product を更新させる
//             setUsers(data)
//         })
//     }, []);
    
//     // const uid = getUserId(selector)
//     // const username = getUsername(selector)

//         // 画像があるかないか判断する しなければ 仮画像が入る
//         // const images = (props.images.length > 0) ? props.images : [{path:NoImage}]
    
//     // -------------------------------------
//     //  AddProduct関数
//     //  useCallBack は 子コンポネートに渡すために使う
//     // -------------------------------------     
//     const addUsers = useCallback(() => {
//       const timestamp = FirebaseTimestamp.now();
//       dispatch(addProductToCart({
//         added_at: timestamp,
//         name: users.name,
//       }))
//     },[users])

//  return(
//      <>
//      {/* product が存在していたら */}
//          {users && (
//           <p>{users.username}</p>

//          )}
      
//      </>
//  )
// }

// export default User;

// import React from 'react';
// import {UserButton} from '../components/User';
// import styled from 'styled-components';
// import { getUserId,getUsername,getUseremail,getUserPost,getPrefecture,getCity,getOther,getDescription,getImages } from '../reducks/users/selectors';
// import {useSelector,useDispatch} from "react-redux";
// import {Snackbars} from '../components/UIkit';
// import * as AiIcons from "react-icons/ai";
// import {UserMenus} from '../components/User';
// // import{Test} from '../components/Home';

// const Container = styled.div`
//   display: -ms-flexbox;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: flex;
//   margin: 0 auto;
//   max-width: 1080px;
// `;

// const Main = styled.main`
//   width: calc(100% - 300px);
// `;

// const CenterSpacer = styled.div`
//  margin: 0 1em;
// `;

// const ButtonArea = styled.div`
//     display: flex;
//     justify-content: center;
// `;


// const Sidebar = styled.aside`
//   width: 300px;
// `;

// const Contents = styled.aside`
// align-items:center;
//   display:flex;
// justify-content:center;
// `;

// const Title= styled.h3`
//  font-size: 1.5em;
//  font-weight: bold;
// `;

// const Content= styled.div`
//  p {
//     text-align:left;
//     font-weight: bold;
//  }
// `;

// const BackStyle= styled.p`
//  background-color: #efefef;
//  border-radius: 0.5em;
//  padding: 0.5em 1em;
//  font-weight: normal !important;
//  margin: 1em 0;
// `;


// const User = (props) => {
//     const dispatch = useDispatch();
//     const selector = useSelector(state => state);

//     const uid = getUserId(selector);
//     const username = getUsername(selector);
//     // const email = getUserMail(selector);
//     const userEmail = getUseremail(selector)
//     const post = getUserPost(selector);
//     const prefecture = getPrefecture(selector);
//     const city = getCity(selector);
//     const other = getOther(selector);
//     const description = getDescription(selector);
//     const images = getImages(selector);
    
    
//     // const MenuLists1 = [
//     //     {
//     //         text: 'お知らせ',
//     //         path: '/user/notification'
//     //     },
//     //     {
//     //         text: 'いいねした商品',
//     //         path: '/user/like'
//     //     },
//     // ]

//     // const MenuLists2 = [
//     //     {
//     //         text: '商品を出品する',
//     //         path: '/product/edit'
//     //     },
//     //     {
//     //         text: '出品した商品',
//     //         path: '#'
//     //     }
//     // ]

//     // const MenuLists3 = [
//     //     {
//     //         text: 'お問い合わせ',
//     //         path: '#'
//     //     },
//     //     // {
//     //     //     text: 'ログアウト',
//     //     //     path: '#'
//     //     // }
//     // ]
   
//  return(
//      <>

//      <div className="common__item">
//      <div className="common__center">
//          <Container>
//          <Sidebar >
//        <UserMenus />
//     </Sidebar>

//            <CenterSpacer />

//            <Main>
//                <Snackbars
//                icon={<AiIcons.AiFillNotification />}
//                text={"あなたはまだ会員情報を記入していません。記入してください。"}
//                color={"snackbars-news"}
//                />
//                <Title>あなたの設定</Title>

//            <Contents>

//             {/* <UserImage images={images} /> */}
//            {/* <p><img src="https://placehold.jp/200x200.png" alt="画像"/></p> */}
//            {/* <div>
//                <p>id: {uid}</p>

//            </div> */}
//            </Contents>

//            <Content>
//            {images}
//            <p>ユーザー名</p>
//            <BackStyle>{username}</BackStyle>
//            <p>メールアドレス</p>
//            <BackStyle>{userEmail}</BackStyle>
//            <p>住所</p>
//            <BackStyle>{post}</BackStyle>
//            <BackStyle>{prefecture}</BackStyle>
//            <BackStyle>{city}</BackStyle>
//            <BackStyle>{other}</BackStyle>
//            <p>自己紹介</p>
//            <BackStyle>{description}</BackStyle>

//            {/* <Test link={'/contact'} /> */}

//            </Content>

//           <ButtonArea>
//           {/* <AccountButton text={'内容を修正する'} onClick={() => dispatch(push('/product/edit/'+id))} /> */}
//           <UserButton id={uid} />

//           </ButtonArea>

//            </Main>
//          </Container>
//      </div>
//      </div>
 
//      </>
//  )
// }

// export default User;


// データ読み込み
import React,{useState,useCallback,useEffect} from 'react';
import styled from 'styled-components';
import {db} from '../firebase';
import {UserMenus,UserEditButton} from '../components/User';
import {ImageSwiper} from '../components/Products';
import {useDispatch,useSelector} from "react-redux";
import { getUserId,getUsername } from '../reducks/users/selectors';
import image from '../assets/img/user.svg';

const Container = styled.div`
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  max-width: 1080px;
`;

const Sidebar = styled.aside`
  width: 300px;
`;

const Title= styled.h3`
 font-size: 1.5em;
 font-weight: bold;
 padding-top:1em;
`;

const Main = styled.main`
  width: calc(100% - 300px);
  p {
    padding:0;
    font-weight: bold;
    padding: 1em 0 4em 0;
  }
  img {
    width:350px;
  }
`;

const CenterSpacer = styled.div`
 margin: 0 1em;
`;

const Center = styled.div`
 text-align: center;
`;

const Button = styled.button`
 text-align: center;
 margin: 0 auto;
`;

const BackStyle = styled.div`
 text-align: center;
 padding: 1em;
 background-color:  #efefef;
 border-radius: 1em;
 width: 100%;
 margin: 1em 0;
 span {
   font-weight: bold;
 }
`;

const Informations = styled.div`
 padding: 2.5em 0;
`;

const Item = styled.div`
text-align: left;
 label {
  text-align: left;
 }
`;

const User = () => {
  const dispatch = useDispatch();
  　const selector = useSelector((state) => state);
  const uid = getUserId(selector);
  // id取得のためにpathを取得する
  const path = selector.router.location.pathname;
  const id = path.split('/user/')[1];

  const username = getUsername(selector);

  const [user, setUser] = useState(null);

  // レンダー が走った後に useEffect が走る
  useEffect (() => {
    // pathにより抽出したid
    db.collection('users').doc(id).get()
    // document を受け取る
    .then(doc => {
      // document の data を取得
       const data = doc.data();
       // setUse が user が更新する
       setUser(data)
    })
   }, []);

  return(
    <div className="common__item">
     <div className="common__center">
     <Container>
    <Sidebar >
       <UserMenus />
    </Sidebar>
    <CenterSpacer />
    <Main>
     <Title>ユーザー</Title>
       <p>こんにちは {username} さん<br />今日も素敵なお買い物をしましょう!!</p>
       <img src={image} alt="image"/>
  </Main>
</Container>
     </div>
    </div>
  )
};

export default User;