// import React from 'react';
// import styled from 'styled-components';

// const Contents = styled.div`
//     display: flex;
//     align-items:center;
//     padding-right: 1.5em;

//     i {
//         color: red;
//     }
//     p {
//         margin-left:0.5em;
//         font-weight:bold;
//     }
// `;

// const SignInput = (props) => {
//  return(
// <Contents>
// {props.icon}
//  <p>{props.text}</p>
// </Contents>
//  )
// }

// export default SignInput;

import React,{useState} from 'react';
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import {SidebarData} from '../common';
import {IconContext} from 'react-icons';
import styled from 'styled-components';
// import NavCategory from './NavCategory';
// import {Link} from 'react-router-dom';

const Navbar = styled.div`
    height: 80;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;

    li {
        cursor: pointer;
    }
    p{
        margin-left: 1em;
    }
`;

const IconText = () => {
    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)
 return(
  <>
  <IconContext.Provider value={{color: '#383838'}}>
   <Navbar>

         <FaIcons.FaBars onClick={showSidebar} />
         <p onClick={showSidebar}>カテゴリーから探す</p>

   </Navbar>
   <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
       <ul className='nav-menu-items' onClick={showSidebar}>
           <li className='navbar-toggle'>
               <a href="#" className='menu-bars'>
                   <AiIcons.AiOutlineClose />
               </a>
           </li>

           <SidebarData />
           {/* {sidebarData.map((item, index) => {
               return (
                   <li key={index} className={item.cName}>
                       <a href={item.path}>
                         {item.icon}
                         <span>{item.title}</span>
                       </a>
                   </li>
               );
           })} */}

           
       </ul>
       {/* <div className="nav-back" onClick={showSidebar} /> */}
       {/* <NavCategory /> */}
   </nav>
   </IconContext.Provider>
  </>
 )
}

export default IconText;
