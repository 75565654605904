import React from 'react';
import * as FaIcons from "react-icons/fa";
import styled from 'styled-components';
import {IconText,AccountButton} from '../UIkit'
import {getIsSignedIn} from "../../reducks/users/selectors";
import {useSelector} from "react-redux";
import {IconMenu} from '../common';


const Size = styled.div`
width: 100%;
    margin: 0 auto;
    max-width: 1100px;

padding: 0 1em;
display:flex;
justify-content: space-between;
`;

const Categorys = styled.div`
width: 100%;
background-color: #fff;
display:flex;
justify-content:center;
align-items: center;
border-bottom: solid 1px #ddd;
`;

const Contents = styled.div`
display:flex;
`;

const CategoryBar = () => {
    const selector = useSelector((state) => state);
    // Selector から getIsSignedIn 関数を取得
    const isSignedIn = getIsSignedIn(selector);

    const ButtonLists = [
        {
          text: '新規会員登録',
          path: '/signup'
        },
        {
          text: 'ログイン',
          path: '/signin'
          }
      ];

    // const ContentsLists = [
    //     {
    //       text: 'カテゴリーから探す',
    //       icon: <FaIcons.FaBars />
    //     },
    //     {
    //       text: '人気ブランドから探す',
    //       icon: <FaIcons.FaBars />
    //       }
    //   ];

      

 return(
     
    <Categorys>
    <Size>



<Contents>
<IconText />
{/* {ContentsLists.map((Lists,List) => {
        return <IconText key={List} text={Lists.text} icon={Lists.icon} />
    })} */}
    
</Contents>



{/* {isSignedIn && (
           <Contents>
           {ButtonLists.map((Lists,List) => {
                   return <AccountButton key={List} text={Lists.text} icon={Lists.icon} path={Lists.path} />
               })}
           </Contents>
)} */}

{/* {isSignedIn && (
           <Contents>
               <IconMenu />
           {ButtonLists.map((Lists,List) => {
                   return <AccountButton key={List} text={Lists.text} icon={Lists.icon} path={Lists.path} />
               })}
           </Contents>
)}

{!isSignedIn && (
           <Contents>
           {ButtonLists.map((Lists,List) => {
                   return <AccountButton key={List} text={Lists.text} icon={Lists.icon} path={Lists.path} />
               })}
           </Contents>
)} */}

{isSignedIn ? (
    <IconMenu />
):(
    <Contents>
    {ButtonLists.map((Lists,List) => {
            return <AccountButton key={List} text={Lists.text} icon={Lists.icon} path={Lists.path} />
        })}
    </Contents>

)}

{/* <Contents>
{ButtonLists.map((Lists,List) => {
        return <AccountButton key={List} text={Lists.text} icon={Lists.icon} path={Lists.path} />
    })}
</Contents> */}

{/* if(!isSignedIn) {
    <Contents>
{ButtonLists.map((Lists,List) => {
        return <AccountButton key={List} text={Lists.text} icon={Lists.icon} path={Lists.path} />
    })}
</Contents>
} else {
    <Contents>
     <p>サインイン</p>
    </Contents>
} */}


    </Size>
    </Categorys>

 
 )
}

export default CategoryBar;




