import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { getProductsInLike } from '../reducks/users/selectors';
import {LikeItem} from '../components/Products';
import {PrimaryButton} from '../components/UIkit';
import styled from 'styled-components';
import {push} from 'connected-react-router';

const Buttons = styled.div`
 padding-top: 3em;
`;

const Items = styled.div`
 padding-top: 1em;
`;

const LikeList = () => {
  const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    // selector から getProductsInCart関数 を呼び出す (現在のカートにある情報)
    const productsInLike = getProductsInLike(selector);

 return(
  <div className="common__item">
  <div className="common__center">
          <h2>あなたのお気に入り商品</h2>
        <Items>
            {/* カート情報 productsInCart関数 を コンポネート で.map する */}
            {productsInLike.length > 0 && (
                productsInLike.map(product => <LikeItem key={product.likeId} product={product} /> )
            )}
        </Items>
      </div>
   </div>
 )
}

export default LikeList;