import React from 'react';
import styled from 'styled-components';
import * as AiIcons from "react-icons/ai";

const Table = styled.div`
 text-align: center;
`;

const Flex = styled.div`
 display:flex;
 align-items: center;
 background-color: #f6f6f6;
`;

const Item = styled.div`
 display: flex;
 align-items: center;
 margin-left: 1.5em;
`;

const SizeTable = (props) => {
    const sizes = props.sizes
 return(
    

     <Table>
     {sizes.length > 0 && (
         sizes.map(size => (
             <Flex key={size.size}>
                   <Item>
                       {size.size}
                   </Item>
                   <Item>
                       {'残り' + size.quantity > 0 ? (
                        <AiIcons.AiFillDelete />
                       ) : (
                           <p>売り切れです</p>
                       )
                    }
                   </Item>
                   {/* <Item>
                   <AiIcons.AiFillDelete />
                   </Item> */}
             </Flex>
         )))
     }
   </Table>
 )
}

export default SizeTable;
