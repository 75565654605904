import * as Actions from './actions';
import initialState from '../store/initialState';

// UsersReducer
export const ContactsReducer = (state = initialState.contacts, action) => {
    switch (action.type) {

            default:
                return state
    }
}