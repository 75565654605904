import React from 'react';
import {CategoryChip} from '../UIkit';
import styled from 'styled-components';

const Contents = styled.div`
  padding-top:2em;
`;

const PopularCategory = () => {

    const Lists = [
        {
            name: 'ゲーム',
            path: '/products/?category=games'
        },
        {
            name: '書籍・コミック',
            path: '/products/?category=books'
        },
        {
            name: 'コスメ',
            path: '/products/?category=cosmetics'
        },
        {
            name: 'CD・DVD',
            path: '/products/?category=music'
        }
    ]
   
 return(
    <div className="common__item">
    <div className="common__center">
     <h2>人気のカテゴリー</h2>
     <Contents>
     {Lists.map(list => (
                   <CategoryChip key={list.id} name={list.name} path={list.path} />
                 ))}
    </Contents>
     </div>
    </div>
 )
}

export default PopularCategory;