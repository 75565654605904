import React from 'react';
import {UserMenu,UserButton} from '../User';
import {LogoutButton} from '../UIkit';
import { getUserId } from '../../reducks/users/selectors';
import {useSelector} from "react-redux";

const User = (props) => {
  const selector = useSelector((state) => state);
  const uid = getUserId(selector);
  
 return(
     <> 
   <h3 className="UserMenu__title">マイページメニュー</h3>
   <UserButton id={uid} />
   {/* <button onClick={() => dispatch(push('/product/edit/'+id))}>内容を修正する</button> */}
   <UserMenu text={"お知らせ"} link={'/user/notification'} />
   <UserMenu text={"いいねした商品"} link={'/user/like'} />

   <h3 className="UserMenu__title">商品を出品する</h3>
   <UserMenu text={"商品を出品する"} link={'/product/edit'} />

   <h3 className="UserMenu__title">設定・その他</h3>
   <UserMenu text={"お問い合わせ"} link={'/contact'} />
   <LogoutButton />
     </>
 )
}

export default User;


