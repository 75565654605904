import React from 'react';

const ImagePreview = (props) => {
  
 return(
  <div onClick={() => props.delete(props.id)}>
      <img src={props.path} alt="商品画像"/>
  </div>
 )
}

export default ImagePreview;