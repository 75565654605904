// import {db, FirebaseTimestamp} from '../../firebase';
// import {push} from "connected-react-router";
// import {fetchProductsAction} from '../../reducks/products/actions';

// // db.collection('products') を 定数 productRef を代入
// const productRef = db.collection('products')

// // -------------------------------------
// // 商品情報のイテレート用関数
// // -------------------------------------

// export const fetchProducts = () => {
//     return async (dispatch) => {
//         productRef.orderBy('updated_at','desc').get()
//                   .then(snapshots => {
//                       const productList = []
//                       snapshots.forEach(snapshot => {
//                           const product = snapshot.data();
//                           productList.push(product)
//                       })
//                       dispatch(fetchProductsAction(productList))
//                   })
//     }
// }

// // 定数saveProduct は 引数に name,description,category,price,state,deliveryFee,shippingMethod,shippingDay,shippingArea を 引数に渡す。
// // saveProduct関数 は primaryButton に関数として渡す。
// export const saveProduct = (id,name,description,price,state,category,shippingArea,shippingFee,shippingMethod,shippingDay,images) => {
// // thunk型 return async (dispatch)
//     return async (dispatch) => {
//         // FirebaseTimestamp を timestamp に定数化する
//         const timestamp = FirebaseTimestamp.now();

//         // Firebaseに Add したい data を 引数 を使用して定義
//         const data = {
//             // category: category,
//             description: description,
//             name: name,
//             price: price,
//             state: state,
//             images: images,
//             category: category,
//             shippingArea: shippingArea,
//             shippingFee: shippingFee,
//             shippingMethod: shippingMethod,
//             shippingDay: shippingDay
//         }

//         // .set 処理 (新規登録の時のみの処理)
//         if (id === "") {
//         // 定数ref>> に 登録するデータに id を持たせて、 Firebase に .set する設定したい
//         const ref = productRef.doc();
//         // ref.id が これから作る id になる
//         const id = ref.id;
//         // const data に id を追加する
//         data.id = id;
//         //created_at に timestamp を追加する
//         data.created_at = timestamp
//     }

//         // id にdata を set する 更新した部分のみ更新させる (data,{merge: true})
//         return productRef.doc(id).set(data, {merge: true})
//         // 処理をしたら
//         .then(()=>{
//             dispatch(push('/'))
//         }).catch((error) => {
//             throw new Error(error)
//         })
//       }
//   }


import { db, FirebaseTimestamp } from "../../firebase"
import {push} from 'connected-react-router'
import {deleteProductsAction,fetchProductsAction} from "./actions"
import { SetSizeArea } from "../../components/Products"

const productsRef = db.collection('products')

// // -------------------------------------
// // 商品情報のイテレート用関数
// // -------------------------------------

// export const fetchProducts = () => {
//   return async (dispatch) => {
//     productsRef.orderBy('updated_at','desc').get()
//       .then(snapshots => {
//         const productList = [];
//         snapshots.forEach(snapshot => {
//           const product = snapshot.data();
//           productList.push(product);
//         })
//         dispatch(fetchProductsAction(productList))
//     })
//   }
// }

// -------------------------------------
// 商品情報のイテレート用関数 (where条件文)
// -------------------------------------
export const fetchProducts = (category) => {
  return async (dispatch) => {

    let query = productsRef.orderBy('updated_at', 'desc');
    query = category !== '' ? query.where('category', '==', category) : query;
    // query = search !== '' ? query.where('name', '==', search) : query;
    
    query.get()
      .then(snapshots => {
        const productList = [];
        snapshots.forEach(snapshot => {
          const product = snapshot.data();
          productList.push(product);
        })
        dispatch(fetchProductsAction(productList))
    })
  }
}

// export const fetchProducts = (gender, category, search) => {
//   return async (dispatch) => {
//     let query = productsRef.orderBy('updated_at', 'desc');
//     // query = gender !== '' ? query.where('gender', '==', gender) : query;
//     query = category !== '' ? query.where('category', '==', category) : query;
//     // query = search !== '' ? query.where('name', '==', search) : query;
//     query.get()
//       .then(snapshots => {
//         const productList = [];
//         snapshots.forEach(snapshot => {
//           const product = snapshot.data();
//           productList.push(product);
//         })
//         dispatch(fetchProductsAction(productList))
//     })
//   }
// }

// // -------------------------------------
// // トランザクション orderProduct関数はorderconfirm で呼び出す
// // -------------------------------------
// // カート内の商品を引数で受け取る
// export const orderProduct = (productsInCart,amount) => {
//   return async (dispatch,getState) => {
//     // uidを取得
//     const uid = getState().users.uid;
//     // ysers の uid を userRefに代入
//     const userRef = db.collection('users').doc(uid);
//     // リアルタイム取得
//     const timestamp = FirebaseTimestamp.now();

//     // 別途変数取得
//     // cartにある商品が売り切れていると買えないようにする
//     let products = {},
//         soldOutProducts = [];

//     // トランザクション    
//     const batch = db.batch();

//     // 引数で受け取った produstsInCart を product に変更
//     for (const product of productsInCart) {
//       // 商品内のカートのプロダクトのproductOdに対してdbから情報を取得
//       const snapshot = await productsRef.doc(product.productId).get();
//       // 現在の在庫情報確認
//       const sizes = snapshot.data().sizes;

//       //購入したら quantuty が 1減る関数
//       const updatedSizes = sizes.map(size => {
//         if(size.size === product.size) {
//           // 売り切れだったら
//           if(size.quantity === 0) {
//             // soldOutProducts にproductのname を追加
//             soldOutProducts.push(product.name)
//             // souldOut なのでquantityに変化はなし
//             return size
//           }
//           return {
//             size: size.size,
//             quantity: size.quantity -1
//           }
//         } else {
//           // 選んでないサイズでも 変更させない
//           return size
//         }
//       })

//       // productオブジェクト に対して(今回注文したId) をもつ商品
//       // 注文履歴を残したい
//       products[product.productId] = {
//         id: product.productId,
//         images: product.images,
//         name: product.name,
//         price: product.price,
//         size: product.size
//       }

//       // batch 処理
//       batch.update(
//         // 今回注文した商品に対して
//         productsRef.doc(product.productId),
//         // 1減った updatedSizes関数 処理 を更新をかける
//         {sizes: updatedSizes}
//       )

//       // カート内の商品から、userRefのカートから保存したCartIdを参照
//       batch.delete(
//         userRef.collection('cart').doc(product.cartId)
//       )
// }
//   }
// }


// // 売り切れの商品があったら、batchのcommitをせず、エラーを出す
// if (soldOutProducts.length > 0) {
//           const errorMessage = (soldOutProducts.length > 1) ?
//                                 soldOutProducts.join('と'):
//                                 soldOutProducts[0];
//           alert ('大変申し訳ありません。' + errorMessage + 'が在庫切れになったため、注文処理を中断しました。')     
//           return false                 
//        } else {
//   batch.commit()
//   .then(() => {
//   const orderRef = userRef.collection('orders').doc();
//   const date = timestamp.toDate();
//   const shippingDate = FirebaseTimestamp.fromDate(new Date(date.setDate(date.getDate() + 3)));

//   const history = {
//     amount: amount,
//     created_at: timestamp,
//     id: orderRef.id,
//     products: products,
//     shipping__date: shippingDate,
//     updated_at: timestamp
//   }

//   orderRef.set(history)

//   dispatch(push('/order/complete'))

//   }).catch(()=> {
//     alert('注文処理に失敗しました。通信環境をご確認の上、もう一度お試しください。')
//     return false
//   })
// }
// }
// }
export const orderProduct = (productsInCart,amount) => {
  console.log(productsInCart)
  return async (dispatch, getState) => {
    const uid = getState().users.uid;
    const userRef = db.collection('users').doc(uid);
    const timestamp = FirebaseTimestamp.now;

    let products = {},
        soldOutProducts = [];

     const batch = db.batch()
     
     for (const product of productsInCart) {
       const snapshot = await productsRef.doc(product.productId).get()
       const sizes = snapshot.data().sizes;
       console.log(sizes)
       console.log(product)
       console.log(product.sizes)
       console.log(snapshot)

       const updatedSizes = sizes.map(size => {
         if(size.size === size.size) {
          console.log(product.size)
          console.log(size.size)
          console.log(size.quantity)
          alert('商品処理')
           // 売り切れだったら 無効
           if(size.quantity === 0) {
             alert('商品が有ません')
             soldOutProducts.push(product.name);
             return size
           }
           return{
             size: size.size,
             quantity: size.quantity -1
           }

         } else {
          // 今回選んだサイズでなければ
          console.log('何もしなてないよ')
          console.log(size.name)
          console.log(product)
          console.log(product.name)
          console.log(size)
           return size
         }

       })

      

//         products[product.productId] = {
//           id: product.productId,
//           images: product.images,
//           name: product.name,
//           price: product.price,
//           size: product.size,
//           updated_at: product.updated_at,
//           state: product.state,
//           shippingMethod: product.shippingMethod,
//           shippingFee: product.shippingFee,
//           shippingDay: product.shippingDay,
//           shippingArea: product.shippingArea,
//           description: product.description,
//           category: product.category
//         };


//        batch.update(
//          productsRef.doc(product.productId),
//          {sizes: updatedSizes}
//        )

//        batch.delete(
//          userRef.collection('cart').doc(product.cartId),
//        );
//      }

//      if (soldOutProducts.length > 0) {
//         const errorMessage = (soldOutProducts.length > 1) ?
//                               soldOutProducts.join('と'):
//                               soldOutProducts[0];
//         alert ('大変申し訳ありません。' + errorMessage + 'が在庫切れになったため、注文処理を中断しました。')     
//         return false                 
//      } else {
//         batch.commit()
//         .then(() => {
//           // alert('成功しました')
//           const orderRef = userRef.collection('orders').doc();
         
//           const date = timestamp.toDate()
//           const shippingDate = FirebaseTimestamp.fromDate(new Date(date.setDate(date.getDate() + 3)));

//           const history = {
//             amount: amount,
//             created_at: timestamp,
//             id: orderRef.id,
//             products: products,
//             shipping_date: shippingDate,
//             updated_at: timestamp
//           }

//           // orderRef.set(history);
//           orderRef.set(history).catch(error => console.error("Failed to add order history", error));

//           dispatch(push('/'))
//         }).catch(()=> {
//           alert('注文に失敗しました。通信環境をご確認の上、もう一度おねがいします。')
//           console.log('Error')
//           return false
//         })
     }
  }
}


export const saveProduct = (id,name,description,state,category,shippingArea,shippingFee,shippingMethod,shippingDay,images,price,sizes) => {
    return async (dispatch) => {
      const timestamp = FirebaseTimestamp.now()

      const data = {
        name: name,
        description: description,
        state: state,
        images: images,
        category: category,
        shippingArea: shippingArea,
        shippingFee: shippingFee,
        shippingMethod: shippingMethod,
        shippingDay: shippingDay,
        updated_at: timestamp,
        price: parseInt(price, 10),
        sizes: sizes
      }

      // 新規商品の時に処理を実行 id がない時
  if (id === "") {
        //   新規データを作るけれど、自動idを採番したい
        const ref = productsRef.doc();
        //   自動idを取得
        id = ref.id
        //   データの中に今作成したidを入れる
          data.id = id
        //   新規作成の時にtimestampを入れたい
          data.created_at = timestamp
  }

    //   Firebaseにデータをsetしたらdispatchでhomeに戻る、失敗したら 例外処理 をする {marge: true} で更新する部分の身を更新させる
      // return productsRef.doc(id).set(data, {marge: true})
      // 第2引数 .set(data) は商品を完全に上書きしてしまう 更新した部分だけを更新する方法
      return productsRef.doc(id).set(data, {merge: true})
      .then(()=>{
          dispatch(push('/'))
      }).catch((error) => {
          throw new Error(error)
      })
    }
}

// deleteMenu
export const deleteProduct =  (id) => {
  return async (dispatch, getState) => {
    productsRef.doc(id).delete()
    .then(() => {
      const prevProducts = getState().products.list;
      const nextProducts = prevProducts.filter(product => product.id !== id)
      dispatch(deleteProductsAction(nextProducts))
    })
  }
}


