import React,{useCallback,useState} from 'react';
import {Nav,CategoryBar} from '../common';
// import {ClosableDrawer,HeaderMenus,Menu} from './';

const Header = () => {

    const [open, setOpen] = useState(false);

const handleDrawerToggle = useCallback((event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }
    setOpen(!open);
}, [setOpen,open]);

 return(
    <div className="common__center">
        <Nav />
        
    <CategoryBar />
    {/* ClosableDrawer には open onClose 関数を受け取る */}
    {/* <Menu />
    <HeaderMenus handleDrawerToggle={handleDrawerToggle} />
    <ClosableDrawer open={open} onClose={handleDrawerToggle} /> */}
    </div>
 
 )
}
export default Header;