import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    margin: 0 auto;
    padding: 1.1em 3em;
    background: #ea352d;
    transition: all ease-out .3s;
    color: #fff;
    border-radius:2em;
    width:100%;
    max-width: 15em;
    margin-bottom:1em; 
    label{
      font-size: 1.2em;
    }
    :hover{
    padding: 1.1em 3em;
    background: #aa2e28;
    color: #fff;
    transition: all ease-out .3s;
    border-radius:2em;
    label{
      font-size: 1.2em;
    }
    }
`;

const PrimaryButton = (props) => {
    
 return(
    <Button>
      <label>{props.label}</label>
    </Button>
 )
}

export default PrimaryButton;