import React,{useEffect} from 'react';
import { getUserId,getUsername } from '../reducks/users/selectors';
import {Main,PopularItem,NewProduct,Advertising,Sell, GameProduct,PopularCategory,Campaign} from '../components/Home';
// import {Modal} from '../components/UIkit';
import {useDispatch, useSelector} from "react-redux";
import {fetchProducts} from "../reducks/products/operations";




const Home = () => {

    const dispatch = useDispatch()
    const selector = useSelector((state) => state)
    
    // const uid = getUserId(selector)
    // const username = getUsername(selector)

    // // 表示のため のquery の設定
    // const query = selector.router.location.seach;
    // const category = /^\?category=/.test(query) ? query.split('?category=')[1] : "";

    // useEffect(() => {
    //     dispatch(fetchProducts(category));
    // },[query])


 return(
     <div>
         
      <Main />
      {/* <Modal /> */}
      <PopularCategory />
      <NewProduct />
      <Campaign />
      {/* <PopularItem /> */}
      <Advertising />
      {/* <h2>Home</h2>
      <p>ここはホームです。</p>
      <p>id:{uid}</p>
      <p>ユーザー名前:{username}</p> */}
      <Sell />
      {/* <ToggleButton /> */}
     </div>
 )
}

export default Home;
