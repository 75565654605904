import React from 'react';
import styled from 'styled-components';
import top from '../../assets/img/site-top.png';

const MainImage = styled.div`
  width: 100%;
  height: 0;
  padding-top: calc(400 / 1200 * 100%); 
  background: url(${top}) center center / cover no-repeat;
`;

const Home = () => {


 return(
     <MainImage>
      
     </MainImage>
 )
}

export default Home;
