import React,{useState,useCallback,useEffect} from 'react';
import {useDispatch} from "react-redux";
import styled from 'styled-components';
import {UserMenus} from '../components/User';
import {push} from 'connected-react-router';
import {saveContact} from '../reducks/Contacts/operations';
import { db } from '../firebase';

import {TextInput,SelectBox,TextArea,PrimaryButton} from '../components/UIkit';

const Container = styled.div`
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  max-width: 1080px;
`;

const Main = styled.main`
  width: calc(100% - 300px);
`;

const CenterSpacer = styled.div`
 margin: 0 1em;
`;

const ButtonArea = styled.div`
    display: flex;
    justify-content: center;
`;


const Sidebar = styled.aside`
  width: 300px;
`;

const Contents = styled.aside`
align-items:center;
  display:flex;
justify-content:center;
`;

const Title= styled.h3`
 font-size: 1.5em;
 font-weight: bold;
`;

const Content= styled.div`
 p {
    text-align:left;
    font-weight: bold;
 }
`;

const BackStyle= styled.p`
 background-color: #efefef;
 border-radius: 0.5em;
 padding: 0.5em 1em;
 font-weight: normal !important;
 margin: 1em 0;
`;

const Button = styled.button`
    margin: 0 auto;
    padding: 1.1em 3em;
    background: #ea352d;
    transition: all ease-out .3s;
    color: #fff;
    border-radius:2em;
    width:100%;
    max-width: 15em;
    margin-bottom:1em; 
    label{
      font-size: 1.2em;
    }
    :hover{
    padding: 1.1em 3em;
    background: #aa2e28;
    color: #fff;
    transition: all ease-out .3s;
    border-radius:2em;
    label{
      font-size: 1.2em;
    }
    }
`;

const Notification = () => {
  const dispatch = useDispatch();

      // ----------------------------------
    // DBデータ 出力 (URLからidを取得する)
    // ----------------------------------
    // window.location.pathname でU RL を取得する
    // .split で /product/edit' 後のs番目の要素(id)を取得
    let id = window.location.pathname.split('/contact') [1];
    console.log("Before split /", id)
    
    // id が空でない場合
    if(id !== "") {
        // id に .split で / をつける
        id = id.split('/')[1]
        console.log("After split /",id);
    }
    

  const [type, setType] = useState(""),
        [name, setName] = useState(""),
        [subject, setSubject] = useState(""),
        [text, setText] = useState("")

  const types =  [
    {id: "select1", name: '選択してください'},
    {id: "service", name: 'サービスについて'},
    {id: "other", name: 'その他'},
];
const inputName = useCallback((event) => {
  setName(event.target.value)
},[setName]);

const inputSubject = useCallback((event) => {
  setSubject(event.target.value)
},[setSubject]);

const inputText = useCallback((event) => {
  setText(event.target.value)
},[setText]);
   

        // DBから商品情報を取得する (DBから持ってきた情報を useState のローカルState に反映)
        useEffect(() => {
          // id が空でない場合
          if (id !== "") {
              // productsCollection から products の id を取得する
            db.collection('contacts').doc(id).get()
            // snapshot に id情報を渡し、処理を行う
              .then(snapshot => {
                  // 定数data に 引数snapshotに .data を設定
                  const data = snapshot.data();
                  // useState で設定した ローカルステート に data をマージする
                  setType(data.type);
                  setName(data.name);
                  setSubject(data.subject);
                  setText(data.text);
              })
          }
      }, [id])

      
 return(

<div className="common__item">
<div className="common__center">
    <Container>
    <Sidebar >
       <UserMenus />
    </Sidebar>

      <CenterSpacer />

      <Main>
      <Title>お問い合わせ</Title>

      <SelectBox 
     name={"type"}
     id={"type"}
     label={"問い合わせ種類"}
     options={types}
     select={setType}
     value={type}
     />


<TextInput
      label={"名前"}
      placeholder={"入力してください"}
      type={"text"}
      required={true}
      name={"name"}
      id={"name"}
      className={"name"}
      autocomplete={"off"}
      value={name}
      onChange={inputName}
     />  

      <TextInput
      label={"件名"}
      placeholder={"入力してください"}
      type={"text"}
      required={true}
      name={"subject"}
      id={"subject"}
      className={"subject"}
      autocomplete={"off"}
      value={subject}
      onChange={inputSubject}
     />  

<TextArea onChange={inputText} value={text} />

     <Button onClick={() => dispatch(saveContact(id,type,subject,text,name))}>
       送信の確認
     </Button>

      </Main>
    </Container>
</div>
</div>

 )
}

export default Notification;